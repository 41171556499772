import {withStyles, createStyles, withTheme} from "@material-ui/core";
import React from "react";
import NavPopover from "./NavPopover";
import I18nMessage from "./i18n/I18nMessage";
import SearchBox from "./SearchBox";
import clsx from 'clsx';
import RenderHtml from "./RenderHtml";

const heroStyles = createStyles((theme: any) => ({
    hidePrint: theme.hidePrint,
    hero: theme.hero,
    heroTitleWrapper: theme.heroTitleWrapper,
    heroTitle: theme.heroTitle,
    heroNavPopover: theme.heroNavPopover,
    heroNavContainer: theme.heroNavContainer,
    heroSubTitle: theme.heroSubTitle,
    heroButton: theme.heroButton,
    heroSearchWrapper: theme.heroSearchWrapper,
    heroSearchBox: theme.heroSearchBox,
    heroSearchBoxIcon: theme.heroSearchBoxIcon
}));

interface IHeroProps {
    defaultSectionIcon: any;
    combinedSiteSections?: any[];
    classes?: any;
    className?: string;
    theme?: any;
    treePath?: string;
    activeContent?: any;
    activeSection?: any;
    section?: string;
    sectionLabel?: string;
    searchSuggestions?: any;
    customSearchBox?: string;
}

const Hero = ({
                  defaultSectionIcon,
                  combinedSiteSections,
                  classes,
                  theme,
                  className,
                  treePath,
                  activeContent,
                  activeSection,
                  section,
                  sectionLabel,
                  searchSuggestions,
                  customSearchBox
              }: IHeroProps) => {
    const {content, children, ...restContent} = activeContent
    return (<div className={`${classes.hero} ${classes.hidePrint} ${className}`}>
            <div className={`${classes.heroTitleWrapper} ${className}`}>
                <NavPopover
                    defaultIcon={defaultSectionIcon}
                    showHomeButton={true}
                    links={combinedSiteSections}
                    title={theme.navPopover?.message}
                    className={classes.heroNavContainer}
                    popoverClassName={classes.heroNavPopover}
                    popoverProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }
                    }}
                    labelClassName={classes.heroButton}/>

                <I18nMessage Component={theme.heroTitle?.component}
                             variant={theme.heroTitle?.variant}
                             className={classes.heroTitle}
                             id={theme.heroTitle?.message}
                             values={{page: className, content: restContent, section: activeSection}}/>
                <I18nMessage Component={theme.heroSubTitle?.component}
                             variant={theme.heroSubTitle?.variant}
                             className={classes.heroSubTitle}
                             id={theme.heroSubTitle?.message}
                             values={{page: className, content: restContent, section: activeSection}}/>
                <div className={clsx({
                    [classes.heroSearchWrapper]: true,
                    [className]: true
                })}>
                    {customSearchBox != null ? <RenderHtml>{customSearchBox}</RenderHtml> :
                        <SearchBox
                            id={"search-hero"}
                            icon={theme.heroSearchBoxIcon?.name}
                            iconHref={theme.heroSearchBoxIcon?.href}
                            iconClassName={classes.heroSearchBoxIcon}
                            treePath={treePath}
                            section={section}
                            searchLabel={sectionLabel}
                            pageClassName={className}
                            className={classes.heroSearchBox}
                            searchSuggestions={searchSuggestions}
                        >
                        </SearchBox>
                    }
                </div>
            </div>
        </div>
    );
}

Hero.defaultProps = {
    combinedSiteSections: [],
    runtimeConfig: {search: {}},
    activeContent: {},
    activeSection: {},
}
//@ts-ignore
export default withTheme(withStyles(heroStyles, {name: "Hero"})(Hero))
