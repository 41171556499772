import React from "react";
import {
    createStyles,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails, withTheme,
} from "@material-ui/core";
import PortalFontIcon from "./PortalFontIcon";
import I18nMessage from "./i18n/I18nMessage";

const linkAccordionStyles = createStyles((theme: any) => ({
    linkAccordion: theme.linkAccordion,
    linkAccordionSummary: theme.linkAccordionSummary,
    linkAccordionDetails: theme.linkAccordionDetails
}));

interface IPortalAccordionProps {
    classes?: any,
    className?: string,
    onItemClick?: () => void,
    children?: any,
    summaryLabel?: any,
    icon?: any,
    defaultExpanded?: boolean,
    hideAccordion?: boolean
}
const PortalAccordion = ({classes, children, summaryLabel, className, icon, defaultExpanded}: IPortalAccordionProps) => {
    return (<Accordion className={`${classes.linkAccordion} ${className}`} defaultExpanded={defaultExpanded}>
                <AccordionSummary className={classes.linkAccordionSummary} expandIcon={icon}>
                    <I18nMessage id={summaryLabel}/>
                </AccordionSummary>
                <AccordionDetails className={classes.linkAccordionDetails}>
                    {children}
                </AccordionDetails>
            </Accordion>);
}

PortalAccordion.defaultProps = {
    icon: <PortalFontIcon name="expand_more"/>,
    className: "",
    summaryLabel: "",
    links: [],
    defaultExpanded: false,
    hideAccordion: false
}

export default withTheme(withStyles(linkAccordionStyles, {name: "PortalAccordion"})(PortalAccordion))