import React from "react";
import {
    createStyles,
    withStyles,
    Button,
    List,
    Drawer, withTheme
} from "@material-ui/core";
import PortalFontIcon from "./PortalFontIcon";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";

const portalDrawerStyles = createStyles((theme: any) => ({
    appBarDrawerHome: theme.appBarDrawerHome,
    appBarDrawerHomeIcon: theme.appBarDrawerHomeIcon,
    appBarDrawerButton: theme.appBarDrawerButton,
    appBarDrawerButtonIcon: theme.appBarDrawerButtonIcon,
    appBarDrawerHomeImage: theme.appBarDrawerHomeImage,
    appBarDrawerPaper: theme.appBarDrawerPaper,
    appBarDrawerHeader: theme.appBarDrawerHeader,
    appBarDrawerImage: theme.appBarDrawerImage,
    appBarDrawerCloseButton: theme.appBarDrawerCloseButton,
    appBarDrawerCloseIcon: theme.appBarDrawerCloseIcon,
    appBarDrawerAccordionWrapper: theme.appBarDrawerAccordionWrapper,
}));

interface IPortalDrawerProps {
    classes?: any,
    className?: string,
    theme?: any;
    children?: any;
    drawerOpen?: any;
    onClose?: any;
}

const PortalDrawer = ({classes, onClose, theme, drawerOpen, children}: IPortalDrawerProps) => {
    return (<Drawer
        open={drawerOpen}
        onClose={onClose}
        classes={{paper: classes.appBarDrawerPaper}}
    >
        <div className={classes.appBarDrawerHeader}>
            {theme.appBarDrawerImage?.imageSrc ?
                <img src={theme.appBarDrawerImage?.imageSrc}
                     alt={"close"}
                     className={`no-alt ${classes.appBarDrawerImage}`}
                /> : null}
            <Button className={classes.appBarDrawerCloseButton}
                    onClick={onClose}>
                <PortalFontIcon className={classes.appBarDrawerCloseIcon}
                                name={theme.appBarDrawerCloseIcon?.name || "close"}/>
            </Button>
        </div>
        <List className={classes.appBarDrawerAccordionWrapper} role="presentation">
            <I18nMessage Component={PortalLink}
                         href={"/"}
                         id={"label.home"}
                         className={classes.appBarDrawerHome}>
                <PortalFontIcon className={classes.appBarDrawerHomeIcon}
                                name={(theme.appBarDrawerHomeIcon &&
                                    theme.appBarDrawerHomeIcon?.name) || "home"}/>
            </I18nMessage>
            {children}
        </List>
    </Drawer>);
}

PortalDrawer.defaultProps = {}

export default withTheme(withStyles(portalDrawerStyles, {name: "AppBar"})(PortalDrawer))