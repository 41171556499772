import React from "react";
import {withStyles, createStyles, Select, MenuItem, withTheme} from "@material-ui/core";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";

const {I18nContext} = require("../i18n/index")
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";
import I18nMessageInsertionPoint from "../components/I18nMessageInsertionPoint";

const footerStyles = createStyles((theme: any) => ({
    hidePrint: theme.hidePrint,
    footer: theme.footer,
    localeSelect: theme.localeSelect,
    localeSelectProps: theme.localeSelectProps,
    footerRelatedResourcesContainer: theme.footerRelatedResourcesContainer,
    footerRelatedResources: theme.footerRelatedResources,
    footerRelatedResourcesHeader: theme.footerRelatedResourcesHeader,
    footerRelatedResourcesDescription: theme.footerRelatedResourcesDescription,
    footerRelatedResourcesText: theme.footerRelatedResourcesText,
    footerContainer: theme.footerContainer,
    footerLinkHeader: theme.footerLinkHeader,
    footerLinkWrapper: theme.footerLinkWrapper,
    footerLinkItemWrapper: theme.footerLinkItemWrapper,
    footerLinkContainer: theme.footerLinkContainer,
    footerLinkItemText: theme.footerLinkItemText,
    footerLinkItem: theme.footerLinkItem,
    footerSiteNavWrapper: theme.footerSiteNavWrapper,
    footerSiteNav: theme.footerSiteNav,
    footerSiteNavHeader: theme.footerSiteNavHeader,
    footerSiteNavText: theme.footerSiteNavText,
    footerLinkHeaderImage: theme.footerLinkHeaderImage,
    footerCopyright: theme.footerCopyright
}));

export interface IFooterProps extends WithRouterProps {
    classes?: any;
    theme?: any;
    //TODO: remove runtimeConfig
    runtimeConfig?: any[];
    relatedResources: any[];
    
    //TODO: refactor siteSections and externalSites use the same interface, which is defined in README.md
    siteSections: any[];
    //TODO remove externalSites or refactor to use this
    externalSites: any[];
    
    groups: any;
    section?: string;
    copyright?: string;
    //TODO: use or remove linksHeader
    linksHeader?: any;
    logoStyle?: any;
    //TODO: use logoAlt
    logoAlt?: any;
    logoSrc?: any;
    links?: any[];
}

class Footer extends React.Component<IFooterProps> {
    constructor(props) {
        super(props);
    }
    
    static defaultProps = {
        relatedResources: [],
        siteSections: [],
        externalSites: [],
        groups: {},
        links: [],
        copyright: 'message.footer.copyright'
    }
    
    static contextType = I18nContext;

    render() {
        const combinedSiteSections = [...this.props.siteSections, ...(this.props.groups.externalSites  || [])];
        return (
            <div className={`${this.props.classes.footer} ${this.props.classes.hidePrint}`}>
                {this.props.children
                    ? this.props.children
                    : (<>
                        <locale-selector
                            locale={this.props.router?.locale || "en"}    
                            className={this.props.classes.localeSelect} 
                            {...this.props.theme.localeSelectProps || {}}
                        />
                        <div className={this.props.classes.footerContainer}>
                            <div className={this.props.classes.footerSiteNavWrapper}>
                                <I18nMessage id={"message.explore"}
                                             values={{section: this.context.get('section')}}
                                             className={this.props.classes.footerSiteNavHeader}/>
                                <div className={this.props.classes.footerSiteNav}>
                                    {combinedSiteSections.map((obj, index) => {
                                        return <PortalLink key={obj?.href + index}
                                                           href={obj?.href}
                                                           target={obj?.target}
                                        >
                                            <I18nMessage id={obj?.label || obj?.title}
                                                         className={this.props.classes.footerSiteNavText}/>
                                        </PortalLink>
                                    })}
                                </div>
                            </div>
                            {this.props.relatedResources.length > 0 ?
                                (<div className={this.props.classes.footerRelatedResourcesContainer}>
                                    <I18nMessage id="label.related-resources"
                                                 className={this.props.classes.footerRelatedResourcesHeader}/>
                                    <div className={this.props.classes.footerRelatedResources}>
                                        {this.props.relatedResources.map((obj, index) => {
                                                const shortDescription = obj?.shortDescription || obj?.shortdesc || ""

                                                return (
                                                    <PortalLink className={this.props.classes.footerRelatedResourcesText}
                                                                {...obj}
                                                                key={obj?.href + index}
                                                                target="_blank"
                                                    >
                                                        <I18nMessage id={obj?.label || obj?.title}
                                                                     className={this.props.classes.footerRelatedResourcesText}/>
                                                        {shortDescription ? (<I18nMessage id={shortDescription}
                                                                                          className={this.props.classes.footerRelatedResourcesDescription}/>) : null}
                                                    </PortalLink>)
                                            }
                                        )}
                                    </div>
                                </div>) : null}
                            <div className={this.props.classes.footerLinkWrapper}>
                                {this.props.logoSrc ? (
                                    <img className={`no-alt ${this.props.classes.footerLinkHeaderImage}`}
                                         style={{...this.props.logoStyle}}
                                         alt={"logo"}
                                         src={this.props.logoSrc}/>
                                ) : null}
                                {this.props.linksHeader ? (<I18nMessage className={this.props.classes.footerLinkHeader}
                                                                        id={this.props.linksHeader}/>) : null}
                                <div className={this.props.classes.footerLinkContainer}>
                                    {this.props.links.map((footerLink, i) => {
                                        let {href, ...link} = footerLink;
                                        return (
                                            <div key={href + i}
                                                 className={`${this.props.classes.footerLinkItemWrapper} ${link.className}`}>
                                                <PortalLink className={this.props.classes.footerLinkItem}
                                                            href={href} {...{...link, target: "_blank"}}>
                                                    <I18nMessage Component={link.component || 'p'}
                                                                 id={footerLink.text}
                                                                 className={`${this.props.classes.footerLinkItemText} ${link.className || ''}`}/>
                                                </PortalLink>
                                            </div>)
                                    })}
                                </div>
                            </div>
                            <I18nMessage id={this.props.copyright} className={this.props.classes.footerCopyright}/>
                            <I18nMessageInsertionPoint id="footerContainer.bottom"/>
                        </div>
                    </>)}
                <I18nMessageInsertionPoint id="footer.bottom"/>
            </div>
        );
    }
}

export default withRouter(withStyles(footerStyles, {
        name: "Footer"
    }
)(withTheme(Footer)));
