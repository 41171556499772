import React from "react";
import {Button, Popover, ClickAwayListener, withStyles, createStyles} from "@material-ui/core";

const portalPopoverStyles = createStyles((theme: any) => ({
    portalPopover: theme.portalPopover,
    portalPopoverWrapper: theme.portalPopoverWrapper,
    portalPopoverTrigger: theme.portalPopoverTrigger,
    portalPopoverLabel: theme.portalPopoverLabel,
}));

const PortalPopover = ({TriggerComponent, triggerProps, labelProps, classes, onClick, ...props}: any) => {
    const [anchor, setAnchor] = React.useState(null);
    return (
        <ClickAwayListener onClickAway={() => setAnchor(null)}>
            <span className={`${props.className} ${classes.portalPopoverWrapper}`}>
                <TriggerComponent
                    {...triggerProps}
                    className={`${classes.portalPopoverTrigger} ${triggerProps.className}`}
                    onClick={(event) => onClick(event) !== false && setAnchor(anchor ? null : event.currentTarget)}
                >
                    <span
                        {...labelProps}
                        className={`${classes.portalPopoverLabel} ${labelProps.className}`}>
                        {props.label}
                    </span>
                    <Popover
                        open={!!anchor}
                        anchorEl={anchor}
                        className={classes.portalPopover}
                        onClose={() => setAnchor(null)}
                        {...props.popoverProps}
                    >
                        {props.children}
                    </Popover>
                </TriggerComponent>
            </span>

        </ClickAwayListener>
    )
};

PortalPopover.defaultProps = {
    popoverProps: {},
    className: '',
    anchorEl: null,
    label: '',
    onClick: ()=>{},
    labelProps: {className: ''},
    TriggerComponent: Button,
    triggerProps: {className: ''}
}

export default withStyles(portalPopoverStyles, { name: "PortalPopover" })(PortalPopover)
