import React from "react";
import {withStyles, WithStyles, createStyles, withTheme, MenuItem} from "@material-ui/core";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";
import Footer from './Footer';
import PortalAppBar from './PortalAppBar';
import NotificationBanner from './NotificationBanner';
import Hero from "./Hero";
import I18nMessage from "./i18n/I18nMessage";
import PortalTabs from "./PortalTabs";
import PortalLink from "./PortalLink";
import PortalPopover from "./PortalPopover";
import RenderHtml from "./RenderHtml";
import PortalFontIcon from "./PortalFontIcon";
import {DEFAULT_LOGIN_ROUTE, DEFAULT_LOGOUT_ROUTE} from "../utils/_constants";
import DataManager from "../utils/dataManager";
import I18nMessageInsertionPoint from "../components/I18nMessageInsertionPoint";
import {ClientAuthConfig} from "utils/PageUtil";

const layoutStyles = (theme: any) => createStyles({
    '@global': theme['@global'],
    hidePrint: theme.hidePrint,
    layout: theme.layout,
    layoutLogout: theme.layoutLogout,
    layoutLogoutLink: theme.layoutLogoutLink,
    layoutLogoutLinkIcon: theme.layoutLogoutLinkIcon,
    layoutContainer: theme.layoutContainer,
    layoutLoginButton: theme.layoutLoginButton,
    layoutLoginButtonIcon: theme.layoutLoginButtonIcon,
    layoutLoginPopover: theme.layoutLoginPopover,
    layoutHeaderTitle: theme.layoutHeaderTitle,
    layoutHeaderLinks: theme.layoutHeaderLinks,
    layoutHeaderLinksItem: theme.layoutHeaderLinksItem,
    layoutHeaderUserWelcome: theme.layoutHeaderUserWelcome,
});

export interface ILayoutProps extends WithStyles<typeof layoutStyles>, WithRouterProps {
    treePath?: string;
    section?: string;
    footerContent?: any[];
    headerContent?: any[];
    content?: any;
    structure?: any;
    siteSections?: any[];
    groups: {
        relatedResources?: any[];
        externalSites?: any[];
    }
    className?: string;
    theme?: any;
    ssoInfo?: any;
    hideHero?: boolean;
    hideFooter?: boolean;
    hideHeader?: boolean;
    runtimeConfig?: any;
}

class Layout extends React.Component<ILayoutProps, {showLoginLink: boolean}> {
    static defaultProps = {
        runtimeConfig: {footer: {}},
        content: {},
        groups: {},
        jwt: null,
        footerContent: null,
        headerContent: null,
        siteSections: []
    }
    state = {
        showLoginLink: false
    }
    componentDidUpdate() {
        DataManager.set('pageData', this.props);
        const showLoginLink = this.props.runtimeConfig.clientAuthConfig?.hasAuthStrategy && !this.props.ssoInfo;
        if (showLoginLink !== this.state.showLoginLink) {
            this.setState({showLoginLink});
        }
    }

    componentDidMount() {
        DataManager.set('pageData', this.props)
        this.setState({showLoginLink: this.props.runtimeConfig.clientAuthConfig?.hasAuthStrategy && !this.props.ssoInfo})
    }

    render() {
        const {runtimeConfig, classes, ...props} = this.props;
        const combinedSiteSections = [...this.props.siteSections,
            ...(this.props.groups ? (this.props.groups.externalSites || []) : [])
        ];
        // Default active tab to false, not 0. If we're not in a section, we don't want to highlight any tab, not just auto-highlight the first one.
        let activeTabIndex = false as any;
        const clientAuthConfig: ClientAuthConfig = runtimeConfig.clientAuthConfig || {};
        const login_uri = clientAuthConfig.login_uri || DEFAULT_LOGIN_ROUTE;
        const logout_uri = clientAuthConfig.logout_uri || DEFAULT_LOGOUT_ROUTE;
        let activeSection;
        props.siteSections.forEach((p, i) => {
            if (p.value === this.props.router?.query?.section) {
                activeTabIndex = i;
                activeSection = p;
            }
        })
        const headerPos = (this.props.theme.appBarProps && this.props.theme.appBarProps.position) || "static";
        const pathName = this.props.router?.pathname;
        const isLoginOrLogout = pathName === logout_uri || pathName === login_uri;
        const isLogInOutOrRoot = pathName === "/" || isLoginOrLogout;
        const isError = pathName === "/error";
        const retainState = !isError && !isLogInOutOrRoot;
        const showLoginPopover = (clientAuthConfig.hasAuthStrategy && pathName !== login_uri) || null;
        const {asPath, locale} = this.props.router || {};
        const loginHref = (clientAuthConfig.login_uri || DEFAULT_LOGIN_ROUTE) + (retainState ? `?state=/${locale}${asPath}` : "");
        return (
            <>
                <div id="scroll-root" className={`${classes.layout} ${this.props.className} header-${headerPos}`}>
                    {this.props.hideHeader ? null : (this.props.headerContent ? this.props.headerContent.map(c =>
                        <RenderHtml
                            key={c?.uuid}
                            className={c?.outputclasses?.join(' ')}
                        >{c?.content}</RenderHtml>) : <PortalAppBar
                        showSearch={!isLoginOrLogout}
                        className={`${this.props.className} header-${headerPos}`}
                        section={this.props.router?.query?.section as any}
                        treePath={props.treePath}
                        sectionLabel={props.section}
                        relatedResources={props.groups.relatedResources}
                        combinedSiteSections={combinedSiteSections}
                        defaultIcon={runtimeConfig.defaultSectionIcon}
                        searchSuggestions={runtimeConfig.searchSuggestions}
                        customSearchBox={runtimeConfig.htmlReplacements?.searchBoxHeader}
                    >
                        <I18nMessage id={this.props.section}
                                     className={`${this.props.classes.layoutHeaderTitle} ${this.props.className}`}/>
                        <PortalTabs
                            className={`${this.props.className} app-bar header-${headerPos}`}
                            activeTabIndex={activeTabIndex}
                            siteSections={this.props.siteSections}
                        />
                        {this.props.runtimeConfig.header?.links ? <div className={this.props.classes.layoutHeaderLinks}>
                            {this.props.runtimeConfig.header.links.map((p, i) => (
                                <I18nMessage Component={p.component || PortalLink}
                                             {...p}
                                             id={p.text}
                                             key={p.text + i}
                                             className={`${this.props.classes.layoutHeaderLinksItem} ${p.className}`}/>))}
                        </div> : null}
                        {showLoginPopover && (<PortalPopover
                            className={this.props.classes.layoutLoginPopover}
                            triggerProps={{
                                className: this.props.classes.layoutLoginPopover
                            }}
                            popoverProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            }}
                            onClick={() => !this.state.showLoginLink}
                            label={(
                                <I18nMessage
                                    Component={this.state.showLoginLink ? PortalLink : 'span'}
                                    id={`message.user.${this.state.showLoginLink ? 'login' : 'welcome'}`}
                                    values={{...this.props.ssoInfo, user: this.props.ssoInfo}}
                                    className={this.props.classes.layoutLoginButton}
                                    href={this.state.showLoginLink ? loginHref : null}>
                                    <PortalFontIcon
                                        className={this.props.classes.layoutLoginButtonIcon}
                                        name={this.state.showLoginLink ? this.props.theme.layoutLoginButtonIcon?.name : this.props.theme.layoutLoginButtonIcon?.name_logged_in}/>
                                </I18nMessage>)}>
                            <MenuItem className={this.props.classes.layoutLogout}>
                                <I18nMessage Component={PortalLink}
                                             className={this.props.classes.layoutLogoutLink}
                                             id={"message.user.logout"}
                                             shallow={false}
                                             href={logout_uri}>
                                    <PortalFontIcon
                                        className={this.props.classes.layoutLogoutLinkIcon}
                                        name={(this.props.theme.layoutLogoutLinkIcon?.name) || "logout"}/>
                                </I18nMessage>
                            </MenuItem>
                        </PortalPopover>)}
                    </PortalAppBar>)}
                    <NotificationBanner
                        className={`${this.props.className} header-${headerPos}`}
                        href={runtimeConfig.notificationBannerHref}
                    />
                    <div className={`${classes.layoutContainer} ${this.props.className} header-${headerPos}`}>
                        {this.props.hideHero ? null : <PortalTabs
                            className={this.props.className}
                            activeTabIndex={activeTabIndex}
                            siteSections={this.props.siteSections}
                            tabsProps={this.props.theme.portalTabsProps}
                        />}
                        {this.props.hideHero ? null : <Hero className={this.props.className}
                                                            section={this.props.router?.query?.section as any}
                                                            activeContent={this.props.content}
                                                            activeSection={activeSection}
                                                            treePath={props.treePath}
                                                            sectionLabel={props.section}
                                                            defaultSectionIcon={runtimeConfig.defaultSectionIcon}
                                                            combinedSiteSections={combinedSiteSections}
                                                            searchSuggestions={runtimeConfig.searchSuggestions}
                                                            customSearchBox = {runtimeConfig.htmlReplacements?.searchBox}/>}
                        {this.props.children}
                        {this.props.hideFooter
                            ? null
                            : <Footer
                                {...props}
                                {...runtimeConfig.footer}
                                locales={Object.keys(runtimeConfig.i18n || {})}
                                relatedResources={props.groups.relatedResources}
                            >
                                {this.props.footerContent && this.props.footerContent.map(c =>
                                    <RenderHtml
                                        key={c?.uuid}
                                        className={c?.outputclasses?.join(' ')}
                                    >{c?.content}</RenderHtml>)}
                            </Footer>}
                        <I18nMessageInsertionPoint id="layout.bottom"/>
                    </div>
                </div>
            </>
        );
    }

}

//@ts-ignore
export default withTheme(withStyles(layoutStyles, {name: "Layout"})(withRouter(Layout))) as any;
