import React from "react";
import {createStyles, withStyles, withTheme} from "@material-ui/core"
import PortalPopover from "./PortalPopover";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";

const navPopoverStyles = createStyles((theme: any) => ({
    navPopover: theme.navPopover,
    navPopoverHref: theme.navPopoverHref,
    navPopoverIcon: theme.navPopoverIcon,
    navPopoverTitle: theme.navPopoverTitle,
    navPopoverTitleMessage: theme.navPopoverTitleMessage,
    navPopoverHomeHref: theme.navPopoverHomeHref,
    navPopoverHrefText: theme.navPopoverHrefText,
    navPopoverHomeHrefIcon: theme.navPopoverHomeHrefIcon,
}));

interface INavPopoverProps {
    links?: any[];
    title?: string;
    icon?: string;
    buttonSize?: 'small' | 'medium' | 'large';
    labelClassName?: string;
    popoverClassName?: string;
    homeHref?: string;
    homeIcon?: string;
    homeLabel?: string;
    className?: string;
    classes?: any;
    theme?: any;
    showHomeButton?: boolean;
    defaultIcon?: string,
    popoverProps?: any;
}

const NavPopover = (props: INavPopoverProps) => {
    const {classes, showHomeButton, theme, popoverProps} = props;
    if (!showHomeButton && !props.links.length) {
        return null;
    }
    return (
        <PortalPopover
            anchorReference={'anchorEl'}
            popoverProps={popoverProps}
            className={classes.navPopoverTitle}
            labelProps={{className: props.labelClassName}}
            triggerProps={{
                className: props.popoverClassName
            }}
            label={((
                <>
                    <I18nMessage id={props.title} className={classes.navPopoverTitleMessage}/>
                    <PortalFontIcon className={classes.navPopoverIcon} name={theme.navPopoverIconName || props.icon}/>
                </>
            ))}
        >
            <div className={`${classes.navPopover} ${props.className}`}>
                {showHomeButton === true ? (
                    <I18nMessage Component={PortalLink}
                                 id={props.homeLabel}
                                 href={props.homeHref}
                                 shallow={true}
                                 className={`${classes.navPopoverHref} ${classes.navPopoverHomeHref}`}>
                        <PortalFontIcon name={theme.navPopoverHomeIconName || props.homeIcon}
                                        className={classes.navPopoverHomeHrefIcon}/>
                    </I18nMessage>
                ) : null}
                {props.links.map((link: any, index) => {
                    const label = link?.label || link?.title;
                    const thumbnail = link?.thumbnail || props.defaultIcon;
                    return (
                        <I18nMessage key={link?.href + index}
                                     Component={PortalLink}
                                     target={link?.target}
                                     id={label}
                                     href={link?.href}
                                     className={`${classes.navPopoverHref} ${classes.navPopoverHref + index}`}>
                            {thumbnail ? <img src={thumbnail}
                                 alt={"thumbnail"}
                                 className={`no-alt ${classes.navPopoverIcon}`}/> : null}
                        </I18nMessage>
                    )
                })}
            </div>
        </PortalPopover>
    )
}

NavPopover.defaultProps = {
    links: [],
    title: 'message.explore',
    className: '',
    popoverClassName: '',
    popoverProps: {},
    labelClassName: '',
    icon: 'arrow_drop_down',
    homeIcon: "home",
    homeHref: "/",
    homeLabel: "label.home",
    showHomeButton: false,
    buttonSize: 'large' as any,
}
export default withTheme(withStyles(navPopoverStyles, {name: "NavPopover"})(NavPopover))