import React from "react";
import {withStyles, WithStyles, createStyles, withTheme} from "@material-ui/core";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";

const notificationBannerStyles = (theme: any) => createStyles({
    hidePrint: theme.hidePrint,
    notificationBanner: theme.notificationBanner,
    notificationBannerWrapper: theme.notificationBannerWrapper,
    notificationBannerText: theme.notificationBannerText,
    notificationBannerHref: theme.notificationBannerHref,
});

export interface INotificationBannerProps extends WithStyles<typeof notificationBannerStyles>, WithRouterProps {
    className?: string;
    href?: string;
    icon?: string;
    theme?: any;
}

const NotificationBanner = ({classes, href, theme, className}: INotificationBannerProps) => {
    return (
        href ? (
            <div className={`${classes.notificationBanner} ${classes.hidePrint} ${className}`}>
                <div className={classes.notificationBannerWrapper}>
                    <PortalFontIcon name={theme.notificationBannerIcon} />
                    <div className={classes.notificationBannerText}>
                        <I18nMessage Component={PortalLink} id={"message.notification-banner.link-text"} className={classes.notificationBannerHref} href={href}/>
                        <I18nMessage id={"message.notification-banner.text"}/>
                    </div>
                </div>
            </div>
        ) : null)
}

export default withRouter(withTheme(withStyles(notificationBannerStyles, {name: "NotificationBanner"})(NotificationBanner)));
