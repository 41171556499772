import React from "react";
import {I18nContext} from "../i18n/index";
import {I18nMessage} from "../components/i18n/I18nMessage";

export interface I18nMessageInsertionPointProps {
    id: string
}

/**
 * Allows html to be inserted at specific points in the app.
 * If the id is not defined, return an empty div.
 */
export default class I18nMessageInsertionPoint extends React.Component<I18nMessageInsertionPointProps> {

    static contextType = I18nContext;
    
    render() {
        const value = this.context.get(this.props.id)
        const isDefined = value !== this.props.id
        if (isDefined) {
            return <div id={this.props.id}><I18nMessage id={this.props.id}/></div>
        }
        return null
    }

}