import React from "react";
import Link, {LinkProps} from "next/link";
import MUILink, {LinkProps as MUILinkProps} from '@material-ui/core/Link';
import {createStyles, withStyles} from "@material-ui/core";
import path from "path";

const portalLinkStyles = createStyles((theme: any) => ({
    portalLink: theme.portalLink
}))

interface IPortalLinkProps extends LinkProps {
    classes?: any,
    className?: string,
    target?: '_self' | '_blank' | string,
    children?: any,
    ComponentWrapper?: any,
    disabled?: boolean,
    scroll?: boolean,
    linkProps?: MUILinkProps;
    ComponentWrapperProps?: any;
    rel?: "noopener" | "noreferrer" | "next" | "prev" | "first" | "last"
    role?: string
    name?: string
}

class PortalLink extends React.Component<IPortalLinkProps> {
    static defaultProps = {
        linkProps: {className: ''},
        replace: false,
        className: '',
        name: '',
        scroll: true,
        shallow: false,
        disabled: false,
        href: "",
        role: "link",
        target: '',
        ComponentWrapper: React.Fragment,
        ComponentWrapperProps: {}
    }

    render() {
        let {
            classes,
            children,
            linkProps,
            as,
            href,
            target,
            className,
            disabled,
            rel,
            role,
            name,
            ...props
        } = this.props;
        let to = href.toString();
        let asPath = (as || href).toString();
        const isExternal = to.startsWith("http") || to.startsWith("mailto:") || to.startsWith("tel:")
        rel = rel || "noopener";
        if (!target) {
            if (isExternal) {
                target = '_blank';
                rel = "noreferrer"
            } else {
                target = '_self';
            }
        }
        if (!isExternal) {
            to = path.join('/', to)
            asPath = path.join('/', asPath)
        }
        const ComponentWrapper = props.ComponentWrapper;
        return (
            <ComponentWrapper {...props.ComponentWrapperProps}>
                <Link {...props}
                      href={to}
                      as={asPath}
                      passHref={true} 
                      prefetch={false}>
                    {/* @ts-ignore */}
                    <MUILink {...linkProps}
                             name={name}
                             role={role}
                             title={name}
                             onClick={() => {
                                 if (props.scroll && typeof window !== "undefined") {
                                     setTimeout(() => {
                                         const scrollRoot = document.getElementById("scroll-root");
                                         scrollRoot && typeof(scrollRoot.scrollTo) === "function" && scrollRoot.scrollTo({
                                             top: 0,
                                             left: 0
                                         });
                                     })
                                 }
                             }}
                             rel={rel}
                             target={target}
                             className={`${classes.portalLink} ${className} ${linkProps.className} ${disabled ? 'disabled-link' : ''}`}>
                        {children || ""}
                    </MUILink>
                </Link>
            </ComponentWrapper>
        )
    }
}

export default withStyles(portalLinkStyles, {name: "PortalLink"})(PortalLink)