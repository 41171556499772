import React from "react";
import PortalLink from "./PortalLink";
import {createStyles, ListItem, ListItemText, withStyles, withTheme} from "@material-ui/core";


const portalLinkListStyles = createStyles((theme: any) => ({
    linkAccordionItem: theme.linkAccordionItem,
    portalLinkListItem: theme.portalLinkListItem
}));
interface IPortalLinkListProps {
    classes?: any;
    onItemClick?: () => void;
    items?: any[];
    ComponentWrapper?: any
    ComponentWrapperProps?: any
}
const PortalLinkList = ({items, onItemClick, classes, ComponentWrapper, ComponentWrapperProps}:IPortalLinkListProps)=> {
    return items?.length ? <ComponentWrapper {...ComponentWrapperProps}>{ items.map((obj, index) => (
        <PortalLink key={obj.href + index.toString()} target={obj.target} href={obj.href} shallow={false}>
            <ListItem button key={obj.title + index} onClick={onItemClick}>
                <ListItemText primary={obj.title} className={`${classes.linkAccordionItem} ${classes.portalLinkListItem}`}/>
            </ListItem>
        </PortalLink>
    ))}</ComponentWrapper> : null;
}
PortalLinkList.defaultProps = {
    ComponentWrapper: React.Fragment,
    items: []
}

export default withTheme(withStyles(portalLinkListStyles, {name: "PortalLinkList"})(PortalLinkList))
