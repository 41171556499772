import PortalLink from "./PortalLink";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withRouter} from "next/router";
import {createStyles, withStyles, withTheme} from "@material-ui/core";
import React from "react";

const portalTabsStyles = createStyles((theme: any) => ({
    portalTabs: theme.portalTabs,
    portalTabsItem: theme.portalTabsItem,
    portalTabsItemText: theme.portalTabsItemText,
}))

interface ITabsProps {
    classes?: any;
    theme?: any;
    className?: string,
    activeTabIndex?: any;
    siteSections?: any;
    router: any;
    tabsProps?: any;
}

const PortalTabs = (props: ITabsProps) => {
    return (<Tabs
        className={`${props.classes.portalTabs} ${props.className}`}
        value={props.activeTabIndex}
        {...props.theme.portalTabsProps}
    >
        {props.siteSections.map((p) => (
            <Tab component={PortalLink}
                 className={`${props.classes.portalTabsItem} ${props.className}`}
                 label={p.title}
                 name={p.title}
                 selected={p.value === props.router?.query?.section}
                 key={p.href}
                 href={`/${p.href}`}
            />
        ))}
    </Tabs>)
}
export default withRouter(withTheme(withStyles(portalTabsStyles, {name: "PortalTabs"})(PortalTabs)))