import React from "react";
import {InputAdornment, InputBase, withStyles, createStyles, withTheme} from "@material-ui/core";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";
import {toSearch} from "@jorsek/content-portal-utils";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";
import {I18nContext} from '../i18n';
import { DEFAULT_SEARCH_ROUTE } from "../utils/_constants";

const searchBoxStyles = createStyles((theme: any) => ({
    searchBox: theme.searchBox,
    searchBoxInput: theme.searchBoxInput,
    searchBoxAdornment: theme.searchBoxAdornment,
    searchBoxIcon: theme.searchBoxIcon,
}));

interface ISearchBoxProps {
    shallow?: boolean,
    treePath?: string | any,
    section?: string | any,
    searchLabel?: string | any,
    searchRoute?: string | any,
    classes?: any;
    theme?: any;
    onChange?: any;
    onSearch?: any;
    className?: string;
    pageClassName?: string;
    iconClassName?: string;
    id?: string;
    icon?: string;
    iconHref?: string;
    searchSuggestions?: boolean;
}

class SearchBox extends React.Component<WithRouterProps & ISearchBoxProps> {
    state = {
        search: '',
        searchPlaceholder: '',
    }
    private _listener: any;
    static defaultProps = {
        icon: 'search',
        iconHref: null,
        iconClassName: '',
        className: '',
        onChange: () => {
        },
        onSearch: () => {
        },
        searchRoute: DEFAULT_SEARCH_ROUTE
    }

    static contextType = I18nContext;

    get searchHref() {
        let facet_section = this.props.treePath ? [this.props.treePath] : [];
        const query = this.props.router?.query;
        let selectedFacets = {}
        if (query) {
            selectedFacets = 
                Object.fromEntries(
                    Object.entries(query)
                    .filter(([key]) => key.startsWith('facet_'))
                )
        }
        return `${this.props.searchRoute}${toSearch({
            queryString: (this.state.search?.trim() || "*"),
            page: 1,
            facet_section,
            ...selectedFacets,
        })}`
    }

    setSearchLabel() {
        this.setState({
            searchPlaceholder: this.getPlaceholder()
        })
    }

    getPlaceholder() {
        return this.context.get("message.search.placeholder", {
            "label.section": this.props.searchLabel || this.context.get('label.tenant'),
            page: this.props.pageClassName
        })
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.searchLabel !== this.props.searchLabel) {
            this.setSearchLabel();
        }
    }

    componentDidMount() {
        this.setState({
            search: this.props.router?.query?.queryString
        })
        this._listener = this.context.subscribe(() => {
            this.setSearchLabel();
        })
    }

    componentWillUnmount() {
        this._listener.destroy();
    }

    render() {
        const searchIconClass = `${this.props.classes.searchBoxIcon} ${this.props.iconClassName}`;
        return this.props.searchSuggestions 
        ?   <type-ahead id={this.props.id} 
                        class={`${this.props.classes.searchBox} ${this.props.className}`}
                        icon={this.props.icon} 
                        placeholder={this.state.searchPlaceholder} 
                        searchsuggestions={this.props.searchSuggestions} 
                        value={this.props.router?.query?.queryString}>
                            {this.props.iconHref && <img src={this.props.iconHref} alt={""} className={searchIconClass} slot="search-icon"/>}
            </type-ahead>
        : <InputBase
            id={this.props.id}
            placeholder={this.state.searchPlaceholder}
            inputProps={{
                value: this.state.search || ''
            }}
            className={`${this.props.classes.searchBox} ${this.props.className}`}
            classes={{input: this.props.classes.searchBoxInput}}
            onChange={({target: {value}}) => {
                this.setState({search: value || ''})
                this.props.onChange(this.state.search)
            }}
            onKeyUp={({key}) => {
                if (key === "Enter") {
                    this.props.router.push(this.searchHref, this.searchHref, {shallow: this.props.shallow});
                    this.props.onSearch(this.state.search)
                }
            }}
            endAdornment={
                <InputAdornment className={this.props.classes.searchBoxAdornment} position="end"
                                onClick={() => this.props.onSearch(this.state.search)}>
                    <PortalLink role={"searchbox"}
                                name={"search"}            
                                href={this.searchHref} shallow={true}>
                        {this.props.iconHref ?
                            <img src={this.props.iconHref} alt={"search"} className={`no-alt ${searchIconClass}`}/> :
                            <PortalFontIcon name={this.props.icon} className={searchIconClass}/>}
                    </PortalLink>
                </InputAdornment>
            }
        />
    }
}

//@ts-ignore
export default withRouter(withTheme(withStyles(searchBoxStyles, {name: "SearchBox"})(SearchBox))) as any;