import React from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    withTheme,
    withStyles, WithStyles, createStyles
} from "@material-ui/core";
import {withRouter} from "next/router";
import NavPopover from "./NavPopover";
import {WithRouterProps} from "next/dist/client/with-router";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";
import PortalDrawer from "./PortalDrawer";
import PortalAccordion from "./PortalAccordion";
import PortalLinkList from "./PortalLinkList";
import PortalSearch from "./PortalSearch";
import I18nMessageInsertionPoint from "../components/I18nMessageInsertionPoint";
import RenderHtml from "./RenderHtml";


const appBarStyles = (theme: any) => createStyles({
    hidePrint: theme.hidePrint,
    appBar: theme.appBar,
    appBarDrawerButton: theme.appBarDrawerButton,
    appBarDrawerButtonIcon: theme.appBarDrawerButtonIcon,
    appBarDrawerSiteSections: theme.appBarDrawerSiteSections,
    appBarDrawerRelatedResources: theme.appBarDrawerRelatedResources,
    appBarHome: theme.appBarHome,
    appBarImage: theme.appBarImage,
    appBarNavPopover: theme.appBarNavPopover,
    appBarNavPopoverWrapper: theme.appBarNavPopoverWrapper,
    appBarToolbar: theme.appBarToolbar,
});

export interface IPortalAppBarProps extends WithStyles<typeof appBarStyles>, WithRouterProps {
    treePath: string;
    className?: string;
    section?: string;
    sectionLabel?: string;
    relatedResources: any[];
    combinedSiteSections: any[];
    defaultIcon?: string;
    title?: string;
    theme?: any;
    showSearch?: boolean;
    children?: React.ReactNode | React.ReactNodeArray
    searchSuggestions?: any
    customSearchBox?: string
}

class PortalAppBar extends React.Component<IPortalAppBarProps> {
    state = {
        drawerOpen: false,
        searchOpen: false
    };

    static defaultProps = {
        relatedResources: [],
        combinedSiteSections: [],
        showSearch: true
    }
    closeDrawer = () => this.setState({drawerOpen: false})
    openDrawer = () => this.setState({drawerOpen: true})

    render() {
        const {
            combinedSiteSections,
            classes,
            theme,
            relatedResources,
            sectionLabel,
            section,
            treePath,
            showSearch,
            searchSuggestions,
            customSearchBox
        } = this.props;
        const showSearchBox = showSearch || null;
        return (
            <AppBar
                {...theme.appBarProps}
                className={`${this.props.classes.appBar} ${this.props.classes.hidePrint} ${this.props.className}`}
            >
                <I18nMessageInsertionPoint id="header.top"/>
                <Toolbar className={`${classes.appBarToolbar} ${this.props.className}`} color="inherit">
                    <PortalDrawer drawerOpen={this.state.drawerOpen} onClose={this.closeDrawer}>
                        <PortalLinkList items={combinedSiteSections}
                                        onItemClick={this.closeDrawer}
                                        ComponentWrapper={theme.siteSectionAccordion?.componentWrapper || PortalAccordion}
                                        ComponentWrapperProps={{
                                            summaryLabel: "label.section",
                                            className: classes.appBarDrawerSiteSections,
                                            defaultExpanded: theme.siteSectionAccordion?.defaultExpanded
                                        }}/>
                        <PortalLinkList items={relatedResources}
                                        onItemClick={this.closeDrawer}
                                        ComponentWrapper={theme.relatedResourcesAccordion?.componentWrapper || PortalAccordion}
                                        ComponentWrapperProps={{
                                            summaryLabel: "label.related-resources",
                                            className: classes.appBarDrawerRelatedResources,
                                            defaultExpanded: theme.relatedResourcesAccordion?.defaultExpanded
                                        }}/>
                    </PortalDrawer>
                    <IconButton
                        className={this.props.classes.appBarDrawerButton}
                        onClick={this.openDrawer}
                    >
                        <PortalFontIcon className={this.props.classes.appBarDrawerButtonIcon}
                                        name={theme.appBarDrawerButtonIcon?.name || "menu"}/>
                    </IconButton>
                    <PortalLink href="/"
                                className={`${this.props.classes.appBarHome} ${this.state.searchOpen && 'search-open'}`}
                                linkProps={{variant: "h5"}}>
                        {theme.appBarImage?.imageSrc ? <img src={theme.appBarImage?.imageSrc}
                             alt={"home"}
                             className={`${this.props.classes.appBarImage} ${this.state.searchOpen && 'search-open'}`}/>: null}
                    </PortalLink>
                    {this.props.children}
                    <div className={this.props.classes.appBarNavPopoverWrapper}>
                        <NavPopover defaultIcon={this.props.defaultIcon}
                                    className={this.props.classes.appBarNavPopover}
                                    links={relatedResources}
                                    popoverProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }
                                    }}
                                    title={"label.related-resources"}/>
                    </div>
                    {showSearchBox && !customSearchBox && (<PortalSearch treePath={treePath}
                                                                         section={section}
                                                                         sectionLabel={sectionLabel}
                                                                         searchSuggestions={searchSuggestions}/>)}
                    {showSearchBox && customSearchBox && (
                        <RenderHtml>{customSearchBox}</RenderHtml>)}
                </Toolbar>
            </AppBar>
        );
    }
}

//@ts-ignore
export default withRouter(withTheme(withStyles(appBarStyles, {name: "AppBar"})(PortalAppBar))) as any;
