import React from "react";
import {
    createStyles,
    withStyles, withTheme,
} from "@material-ui/core";
import PortalFontIcon from "./PortalFontIcon";
import clsx from "clsx";
import SearchBox from "./SearchBox";

const portalSearchStyles = createStyles((theme: any) => ({
    appBarSearchIcon: theme.appBarSearchIcon,
    appBarSearchCollapse: theme.appBarSearchCollapse,
    appBarSearchBox: theme.appBarSearchBox,
    appBarSearchBoxIcon: theme.appBarSearchBoxIcon,
    appBarSearchWrapper: theme.appBarSearchWrapper
}));

interface IPortalSearchProps {
    classes?: any,
    className?: string,
    treePath?: string,
    section?: string,
    sectionLabel?: string,
    searchSuggestions?: boolean;
    theme?: any;
}

class PortalSearch extends React.Component<IPortalSearchProps, {searchOpen: boolean}> {
    state = { searchOpen: false }
    static defaultProps = {
        className: ""
    }
    render() {
        const {classes, theme, className, treePath, section, sectionLabel, searchSuggestions} = this.props;
        return (<>
            <PortalFontIcon className={classes.appBarSearchIcon}
                            name={this.state.searchOpen ? theme.appBarSearchIcon?.close : theme.appBarSearchIcon?.name}
                            onClick={() => this.setState({searchOpen: !this.state.searchOpen})}/>
            <div className={clsx({
                [classes.appBarSearchCollapse]: !this.state.searchOpen,
                [classes.appBarSearchWrapper]: true,
                [className]: true
            })}>
                <SearchBox
                    id={"search"}
                    icon={theme.appBarSearchBoxIcon?.name}
                    iconHref={theme.appBarSearchBox?.href}
                    iconClassName={classes.appBarSearchBoxIcon}
                    treePath={treePath}
                    section={section}
                    searchLabel={sectionLabel}
                    className={classes.appBarSearchBox}
                    pageClassName={className}
                    onSearch={() => this.setState({searchOpen: false})}
                    searchSuggestions={searchSuggestions}
                />
            </div>
        </>);
    }
}

export default withTheme(withStyles(portalSearchStyles, {name: "AppBar"})(PortalSearch))